import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {JwtModule} from '@auth0/angular-jwt';
import {NgIdleModule} from '@ng-idle/core';
import {ErrorHandler, NgModule, Injectable, PLATFORM_ID} from '@angular/core';
import {NgxPermissionsModule} from 'ngx-permissions';
import {Router, RouterModule} from '@angular/router';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {LOADING_BAR_CONFIG, LoadingBarModule} from '@ngx-loading-bar/core';
import {LayoutModule} from '@angular/cdk/layout';
import {HammerGestureConfig, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';

// Helpers

import {AppsComponent} from './apps.component';
import {AuthGuard} from './_guards/auth.guard';
import {BildInterceptor} from './_interceptors/bild.interceptor';
import {NetworkActivityInterceptor} from './_interceptors/network-activity.interceptor';
import {SharedModule} from './bild/_shared/shared.module';
import {environment} from '../environments/environment';
import {routing} from './apps.routing';

// Services
import {AuthService} from './_services/auth.service';
import {UsersService} from './_services/users.service';

// Store

// Config
import {APPS_CONFIG, APPS_DI_CONFIG} from './apps-config.constants';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from "@angular/material/legacy-autocomplete";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {MatLegacyCheckboxModule as MatCheckboxModule} from "@angular/material/legacy-checkbox";
import {MatLegacyChipsModule as MatChipsModule} from "@angular/material/legacy-chips";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatDialogModule} from "@angular/material/dialog";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";
import {MatLegacyMenuModule as MatMenuModule} from "@angular/material/legacy-menu";
import {MatNativeDateModule} from "@angular/material/core";
import {MatLegacyRadioModule as MatRadioModule} from "@angular/material/legacy-radio";
import {MatLegacySelectModule as MatSelectModule} from "@angular/material/legacy-select";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatLegacySnackBarModule as MatSnackBarModule} from "@angular/material/legacy-snack-bar";
import {MatStepperModule} from "@angular/material/stepper";
import {MatTabsModule} from "@angular/material/tabs";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatLegacyTableModule as MatTableModule} from "@angular/material/legacy-table";
import {MatLegacyPaginatorModule as MatPaginatorModule} from "@angular/material/legacy-paginator";
import {MatSortModule} from "@angular/material/sort";
import {SignInComponent} from "./sign-in/sign-in.component";

export function tokenGetter() {
  return localStorage.getItem('access');
}

@Injectable()
export class AppsHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    // swipe: {direction: Hammer.DIRECTION_HORIZONTAL},
    pan: {
      direction: 6,
    },
    pinch: {
      enable: false,
    },
    rotate: {
      enable: false,
    },
  };
}

const providers: any[] = [
  {provide: HTTP_INTERCEPTORS, useClass: BildInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: NetworkActivityInterceptor, multi: true},
  {provide: HAMMER_GESTURE_CONFIG, useClass: AppsHammerConfig},
  {provide: ErrorHandler, useValue: Sentry.createErrorHandler({showDialog: false})},
  {provide: Sentry.TraceService, deps: [Router], useValue: undefined},
];

if (environment.envName !== 'dev' && environment.envName !== 'stag') {
  // providers.push(
  //   { provide: ErrorHandler, useClass: RavenErrorHandler }
  // );
}

@NgModule({
  exports: [

  HttpClientModule,
  NgIdleModule,
  NgxPermissionsModule,
  SharedModule,
  ],
  imports: [
  BrowserModule, BrowserAnimationsModule, BrowserTransferStateModule, CommonModule, RouterModule,
  FormsModule, HttpClientModule, ReactiveFormsModule,
  FlexLayoutModule,
    CommonModule,
    FormsModule, ReactiveFormsModule,

    MatAutocompleteModule, MatButtonModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule,
    MatDialogModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatNativeDateModule,
    MatRadioModule, MatSelectModule, MatSidenavModule, MatSnackBarModule, MatStepperModule,
    MatTabsModule, MatToolbarModule, MatTooltipModule, MatExpansionModule, MatTableModule, MatPaginatorModule, MatSortModule,


    MatAutocompleteModule, MatButtonModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule,
    MatDialogModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatNativeDateModule,
    MatRadioModule, MatSelectModule, MatSidenavModule, MatSnackBarModule, MatStepperModule,
    MatTabsModule, MatToolbarModule, MatTooltipModule, MatExpansionModule, MatTableModule, MatPaginatorModule, MatSortModule,

    NgIdleModule.forRoot(),
  NgxPermissionsModule.forRoot(),

  JwtModule.forRoot({
    config: {
    tokenGetter: tokenGetter,
    allowedDomains: ['localhost:4200', 'localhost:8000', 'stage.bildlab.com', 'bildlab.com']
    }
    }),


  LayoutModule,

  LoadingBarHttpClientModule,
  LoadingBarModule,

  SharedModule.forRoot(),

  routing,
  ],
  declarations: [
    AppsComponent,
    SignInComponent,
  ],
  providers: [
  AuthGuard,
  AuthService,
  UsersService,

  {
  provide: APPS_CONFIG,
  useValue: APPS_DI_CONFIG
  },

  { provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 50 } },

  providers
  ],
  bootstrap: [ AppsComponent ]
  })
export class AppsModule {
  constructor(trace: Sentry.TraceService) {}
}
