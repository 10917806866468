import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {NgxPermissionsModule} from 'ngx-permissions';
import {RouterModule} from '@angular/router';

import {HttpClientJsonpModule} from '@angular/common/http';

@NgModule({
  imports: [
  CommonModule, FormsModule, ReactiveFormsModule, RouterModule,

  HttpClientJsonpModule,
  NgxPermissionsModule.forChild(),
  FlexLayoutModule,
  ],
  declarations: [
  ],
  exports: [
  ],
  })
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
      ],
    };
  }
}
