
export class User {
  id: number;
  firstName: string;
  lastName: string;
  isActive: boolean;
  email: string;
  entity: any;
  isADFSOnly: boolean;
}


export class UserForAuth {
  isADFSOnly: boolean;
}
