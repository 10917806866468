import {ModuleWithProviders} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {SignInComponent} from './sign-in/sign-in.component';

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'talent-portal'},
  {path: 'talent-portal', loadChildren: () => import('./talent-portal/talent-portal.module').then((m) => m.TalentPortalModule), data: {preload: true}},
  {path: 'sign-in', component: SignInComponent},
  {path: '**', redirectTo: 'sign-in'},
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, routerOptions);
