import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {plainToClass} from 'class-transformer';

import {BaseService} from './base.service';
import {User, UserForAuth} from '@models/users';
import {BroadcastChannelService} from '@services/broadcast-channel.service';

@Injectable()
export class UsersService extends BaseService {
  userDataSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(protected http: HttpClient, protected broadcastChannelService: BroadcastChannelService) {
    super(http, broadcastChannelService);
  }

  get userData(): Observable<any[]> {
    return this.userDataSubject.asObservable();
  }

  getUserForAuth(email: string): Observable<UserForAuth> {
    return this.get(`${this.baseUrl}/users/${email}`).pipe(map(
      data => {
        return plainToClass(UserForAuth, <Object>data);
      },
      error => {
        return error;
      }
    ));
  }
}
